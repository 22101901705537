import "./Faq.less";
import React from 'react';
import { Row, Col, Collapse, Typography } from 'antd';
import FadeInSection from "../FadeInSection"

const { Panel } = Collapse;
const { Title } = Typography;

const Faq = () => {
  return (
    <Row gutter={24} justify="center" className="faq">
      <Col xs={22} md={18} lg={12}>
        <Title level={3}>FAQ</Title>

        <Collapse defaultActiveKey={['1']} bordered={false} className="fade-in">
          <Panel header="What is DCG's service area?" key="1">
            <p>We service all of Denver Metro area, including but not limited to Denver, Aurora, Littleton, Arvada, Centennial, Lakewood, Westminster, Boulder, and many other communities.</p>
          </Panel>
          <Panel header="What kind of experience does Denver Cleaning Group, Inc. possess?" key="2">
            <p>We have been in the cleaning industry for over 9 years, and have helped thousands of people keep their homes and businesses clean. We are very good at that!</p>
          </Panel>
          <Panel header="What kind of insurance &amp; bonding do we hold?" key="3">
            <p>We are fully insured and bonded up to $2,000,000. With such high level of coverage your home or business is fully protected against any liability. This also protects our company, allowing us to keep our pricing low and our quality high.</p>
          </Panel>
          <Panel header="Who will come to clean my home or office?" key="4">
            <p>Denver Cleaning Group's cleaning teams consist of highly trained cleaning professionals, all of whom have legal US worker authorization and pass a full background check.</p>
          </Panel>
          <Panel header="I only need 1-time cleaning service, can you help?" key="5">
            <p>Absolutely. Whether you need one-time cleaning only or a recurring cleaning service we have packages to suit your needs. Please see our pricing page for a full list of our packages and pricing associated with them.</p>
          </Panel>
          <Panel header="What are the prices for your services?" key="6">
            <p>We are proud to be one of only few cleaning companies in the country to provide upfront pricing. Please refer to our pricing page for all the information on home cleaning services and prices. If you are interested in our commercial cleaning services, please call us at (303) 848-3884 for pricing or email us using the form on our contact us page to set up your free estimate.</p>
          </Panel>
          <Panel header="How do I contact Denver Cleaning Group?" key="7">
            <p>You can always call us at (303) 848-3884 or email us using the form on our contact us page. We look forward to hearing from you!</p>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default Faq;