import "./AboutHeader.less";
import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Link } from "gatsby"
import Image from "../../get-image"

const { Title } = Typography;

const AboutHeader = () => {
  return (
    <Row gutter={24} justify="center" className="about-header fade-in" align="middle">
      <Col xs={22} md={8} className="about-header__image about-header__col">
        <Image imageName="denver_img.png" className="about-us__image" />
      </Col>

      <Col xs={22} md={10} lg={7} className="about-header__info about-header__col">
        <Title level={2}>Who is Denver Cleaning Group, Inc.?</Title>

        <p>
          We are a professional cleaning company providing Denver House Cleaning, Denver Maid Service, and Denver Office Cleaning services.
        </p>

        <p>
          You can find our Frequently Asked Questions below.
        </p>

        <div>
          Alternatively you can always contact us via our <Link to="/contact">Contact Page</Link> or give us a call at
          <a href="tel:+13038483884"> (303) 848-3884</a>
        </div>
      </Col>
    </Row>
  );
};

export default AboutHeader;