import React from "react"
import { Typography } from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Faq from "../components/Faq"
import AboutHeader from "../components/AboutHeader"

const { Title } = Typography;

const AboutPage = () => (
  <Layout className="about">
    <SEO title="About Us" />

    <Title className="page-title">
      About Us
    </Title>

    <AboutHeader />

    <Faq />

  </Layout>
)

export default AboutPage
